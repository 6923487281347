import anime from 'animejs';

export class LiquidImage {
  constructor(PIXI, options) {
    this.PIXI = PIXI;
    this.options = {
      stageWidth: 1920,
      stageHeight: 1080,
      sprite: null,
      autoPlaySpeed: [5, 1],
      displacementImage: '',
      interactive: false,
      x: '+=25',
      y: '+=25',
      ...options,
    };

    this.renderer = new this.PIXI.autoDetectRenderer({ width: this.options.stageWidth, height: this.options.stageHeight, transparent: true });

    this.stage = new this.PIXI.Container();
    this.displacementSprite = new this.PIXI.Sprite.from(this.options.displacementImage);
    this.displacementFilter = new this.PIXI.filters.DisplacementFilter(this.displacementSprite);

    /// ---------------------------
    //  CENTER DISPLACEMENT
    /// ---------------------------
    this.displacementSprite.anchor.set(0.5);
    this.displacementSprite.x = this.renderer.view.width / 2;
    this.displacementSprite.y = this.renderer.view.height / 2;
  }

  init = () => {
    const ticker = new this.PIXI.Ticker();

    ticker.autoStart = true;

    ticker.add(delta => {
      this.displacementSprite.transform.position.x += this.options.autoPlaySpeed[0] * delta;
      this.displacementSprite.transform.position.y += this.options.autoPlaySpeed[1] * delta;
      this.renderer.render(this.stage);
    });
    anime({
      targets: this.displacementFilter.scale,
      x: this.options.x,
      y: this.options.y,
    });

    if (this.options.interactive === true) {
      let pointerOver = false;
      // HOVER
      this.stage.pointerover = () => {
        pointerOver = true;
      };
      this.stage.pointermove = ({
        data: {
          global: { x, y },
        },
      }) => {
        if (pointerOver)
          anime({
            targets: this.displacementSprite.transform.position,
            x: `+=${(x - this.options.stageWidth / 2) / 2}`,
            y: `+=${(y - this.options.stageHeight / 2) / 2}`,
            easing: 'linear',
            duration: 500,
          });
      };

      this.stage.pointerout = () => {
        pointerOver = false;
      };
    }
    this.initPixi();
    this.loadPixiSprite();

    return this.renderer.view;
  };

  initPixi = () => {
    // Enable Interactions
    this.stage.interactive = true;

    this.renderer.view.style = {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      top: '50%',
      left: '50%',
      transform: 'translate( -50%, -50% )',
      position: 'absolute',
      display: 'block',
    };

    this.displacementSprite.texture.baseTexture.wrapMode = this.PIXI.WRAP_MODES.REPEAT;

    // Set the filter to stage and set some default values for the animation
    this.stage.filters = [this.displacementFilter];

    this.displacementSprite.scale.x = 2;
    this.displacementSprite.scale.y = 2;

    // PIXI tries to fit the filter bounding box to the renderer so we optionally bypass
    this.displacementFilter.autoFit = true;

    this.stage.addChild(this.displacementSprite);
  };

  loadPixiSprite = () => {
    const texture = new this.PIXI.Texture.from(this.options.sprite);
    const image = new this.PIXI.Sprite(texture);

    image.anchor.set(0.5);
    image.x = this.renderer.width / 2;
    image.y = this.renderer.height / 2;
    image.width = this.options.stageWidth;
    image.height = this.options.stageHeight;

    this.stage.addChild(image);
  };
}
