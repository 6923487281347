import React from 'react';
import PropTypes from 'prop-types';

import styles from './LiquidHero.module.scss';
import { LiquidImage } from './LiquidImage';

import displacementImage from '../../static/dmap.jpg';

class LiquidHero extends React.Component {
  async componentDidMount() {
    try {
      const PIXI = await import('pixi.js');
      const liquidImage = new LiquidImage(PIXI, {
        sprite: this.props.imageInfo.childImageSharp.fluid.src,
        displacementImage,
        autoPlaySpeed: [1, 1],
        interactive: true,
      });
      const view = liquidImage.init();
      this.ref.appendChild(view);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { alt, children } = this.props;
    return (
      <div className={styles.liquidHero} title={alt}>
        <div className={styles.children}>{children}</div>
        <div className={styles.wrapper} ref={ref => (this.ref = ref)} />
      </div>
    );
  }
}

LiquidHero.propTypes = {
  imageInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  alt: PropTypes.string.isRequired,
};

export default LiquidHero;
