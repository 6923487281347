import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import styles from './index.module.scss';
import Layout from '../components/Layout';
import Sections from '../components/Sections';
import Section from '../components/Section';
import Lead from '../components/Lead';
import Button from '../components/Button';
import LiquidHero from '../components/LiquidHero';

const IndexPage = ({ data, location: { pathname, state } }) => {
  const {
    index: {
      frontmatter: { meta, sections, lead, heroImage, cta },
    },
    // services: {
    //   frontmatter: {
    //     components: { sections: wordcloud },
    //   },
    // },
  } = data;

  return (
    <Layout className={styles.root} pathname={pathname} meta={meta} state={state}>
      <section className="section">
        <div className="container">
          <LiquidHero imageInfo={heroImage.image} alt="TODO">
            <div className={styles.heroImageContent}>
              <h1 className="title">{heroImage.title}</h1>
              {heroImage.button ? (
                <Button inverted to={heroImage.button.url}>
                  {heroImage.button.text}
                </Button>
              ) : null}
            </div>
          </LiquidHero>
        </div>
      </section>
      <Lead {...lead} />
      {/* {wordcloud.length ? (
        <section className={`section ${styles.wordcloud} ${styles.wordcloud}`}>
          <div className="container">
            {wordcloud.map(({ title }) => (
              <Link key={title} to={`/services`} state={{ scrollTo: title }}>
                {title}
              </Link>
            ))}
          </div>
        </section>
      ) : null} */}
      <Sections indent sections={sections} />
      {cta && cta.body ? <Section {...cta} /> : null}
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.object,
  }),
  data: PropTypes.shape({
    index: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }).isRequired,
        heroImage: PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.object,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }).isRequired,
        lead: PropTypes.shape({
          body: PropTypes.string,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }).isRequired,
        cta: PropTypes.shape({
          body: PropTypes.string,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
        sections: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            body: PropTypes.string,
            image: PropTypes.object,
            button: PropTypes.shape({
              text: PropTypes.string,
              url: PropTypes.string,
            }),
          }).isRequired
        ),
      }),
    }),
    // services: PropTypes.shape({
    //   frontmatter: PropTypes.shape({
    //     components: PropTypes.shape({
    //       sections: PropTypes.arrayOf(
    //         PropTypes.shape({
    //           title: PropTypes.string,
    //         }).isRequired
    //       ),
    //     }),
    //   }),
    // }),
  }),
};

export const indexPageQuery = graphql`
  query indexPage {
    index: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        meta {
          title
          description
        }
        heroImage {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          button {
            text
            url
          }
        }
        lead {
          body
          button {
            text
            url
          }
        }
        cta {
          body
          button {
            text
            url
          }
        }
        sections {
          title
          body
          image {
            childImageSharp {
              fluid(maxWidth: 640, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          button {
            text
            url
          }
        }
      }
    }
    #services: markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
    #  frontmatter {
    #    components {
    #      sections {
    #        title
    #      }
    #    }
    #  }
    #}
  }
`;

export default IndexPage;
